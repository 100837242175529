/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import i18n from "#translate/i18n";
import PeopleImage from "#images/bonus_drawer/header_people.svg";
import GiftImage from "#images/bonus_drawer/gift.svg";
import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from "#components/formComponents/DivisionLine";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import DateInput from "#components/formComponents/DateInput";
import SelectInput from "#components/formComponents/SelectInput";
import CurrencyInput from "#components/formComponents/CurrencyInput";
import createOrUpdateBonusReward from "#requests/finances/createOrUpdateBonusReward";
import fetchPaymentAndRewardTypes from "#requests/finances/fetchPaymentAndRewardTypes";
import fetchBonusReward from '#requests/userProfile/editions/fetchBonusReward'
import bonusSchema from "#validations/bonusSchema";
import { bonusData as initialValues } from "#data/bonusData";
import { yupResolver } from "@hookform/resolvers/yup";
import useOnClickOutside from '#services/useOnClickOutside';
import CloseButton from '#images/add_allowances/close_icon.svg';

export default function Bonus({
  closeBonusDrawer,
  userName,
  user,
  openNewDrawer,
  setShowMessage,
  editObjectId,
  setEditObjectId
}) {
  const formName = "bonus_reward_form"
  const model = "bonus_reward"
  const [paymentTypes, setPaymentTypes] = useState(['']);
  const [rewardTypes, setRewardTypes] = useState(['']);
  const [submittingData, setSubmittingData] = useState(false);
  const bonusDrawer = useRef();
  const bonusAttr = ['bonus_value', 'bonus_date', 'payment_date', 'notes', 'payment_type', 'reward_type']

  useOnClickOutside({
    ref: bonusDrawer,
    handler: () => { closeBonusDrawer(), openNewDrawer?.() },
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(bonusSchema)
  });

  useEffect(() => {
    fetchPaymentAndRewardTypes()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setPaymentTypes(parsedResponse["payment_types"]);
        setRewardTypes(parsedResponse["reward_types"]);
      })
      .then(() => {
        if (editObjectId) {
          fetchBonusReward({ id: editObjectId, user: user })
            .then((parsedResponse) => {
              const data = parsedResponse.data
              bonusAttr.forEach(element => {
                formMethods.setValue(`${model}.${element}`, data[element])
              });
            })
        }
      })
  }, []);

  const onSubmit = (data) => {
    setSubmittingData(true)
    createOrUpdateBonusReward({ data: data, model: model, user: user, objectId: editObjectId })
      .then(() => {
        closeBonusDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'bonus_reward_updated' : 'bonus_reward_created',
          additionalData: editObjectId ? i18n.t(`drawerResponses.success.bonus_reward_updated.rewardType.${data.bonus_reward['reward_type']}`) : ''
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        openNewDrawer?.()
      }).catch(function (error) {
        setSubmittingData(false)
        throw new Error(error);
      })
  };

  return (
    <>
      <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen'>
        <div
          ref={bonusDrawer}
          className="absolute bg-white right-0 top-0 bottom-0 rounded-none w-1/2
                     z-[1500] shadow-[0px_16px_24px_rgba(0,0,0,0.14),0px_6px_30px_rgba(0,0,0,0.12),0px_8px_10px_rgba(0,0,0,0.2)]"
          id='bonus-drawer'
        >
          <div id="bonus_drawer_main_body" className='block fixed right-0 left-1/2 bottom-0 top-0 '>
            <div id="initial_bonus_header_text" className="block h-fit">
              <div className={`${editObjectId ? 'bg-complementary-yellow' : 'bg-rebase-green'} z-1600 flex justify-center h-[90px]
                              md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px]
                              max-h-[15vh]`}
              >
                <img
                  className="text-center h-[95%] self-end"
                  src={PeopleImage}
                  alt={i18n.t("bonusDrawer.peopleHeader")}
                />
                <button
                  id='close_bonus_drawer_button'
                  type='button'
                  className='absolute top-5 right-5'
                  onClick={() => { closeBonusDrawer(), openNewDrawer?.() }}
                >
                  <img
                    id='close_selection_drawer_image'
                    src={CloseButton}
                    alt={i18n.t('buttons.logout')}
                    className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                  />
                </button>
              </div>
              <div className='m-4 flex' >
                <div className="mt-0 mr-4 z-[1650] bg-white" >
                  <img
                    src={GiftImage}
                    alt={i18n.t("bonusDrawer.gift")}
                  />
                </div>
                <div className="mt-0 z-[1600]">
                  <h5 className='font-sans not-italic font-bold text-xl/6' >
                    {i18n.t(`${editObjectId ? 'bonus_reward_update' : 'bonus_reward_create'}.title`)}
                  </h5>
                  <h6 className='font-sans not-italic text-sm/6'>
                    <p className='mt-[5px]'>{i18n.t(`${editObjectId ? 'bonus_reward_update' : 'bonus_reward_create'}.subtitle`)} <span className='font-semibold'>{userName}</span>.</p>
                  </h6>
                </div>
              </div>
            </div>
            <div
              id="bonus_form_start"
              className="block max-h-[85vh] px-6 pb-2.5 pt-0 mt-0 sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0"
            >
              <FormProvider {...formMethods}>
                <form
                  id={formName}
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                  <div
                    id="bonus_form_main_body"
                    className="overflow-y-auto px-2 max-h-[70vh] absolute right-0 left-0 mx-6 px-0.5
                              sm:top-[165px] md:top-[165px] lg:top-[180px] xl:top-[191px] 2xl:top-[226px] 2k-monitor:top-[261px]
                              sm:bottom-[100px] md:bottom-[100px] lg:bottom-[105px] xl:bottom-[105px] 2xl:bottom-[100px]"
                  >
                    <div className="grid grid-cols-2 gap-y-3 gap-x-4">
                      <SelectInput
                        model={model}
                        name="reward_type"
                        options={rewardTypes}
                        title={i18n.t("bonus_reward.reward_type")}
                        className='w-full'
                        requiredField={true}
                      />

                      <CurrencyInput
                        model={model}
                        name="bonus_value"
                        title={i18n.t("bonus_reward.bonus_value")}
                        className='w-full'
                        requiredField={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 space-y-5 mt-[15px]">
                      <TextAreaInput
                        model={model}
                        name="notes"
                        placeholder={i18n.t("bonus_reward.notes_placeholder")}
                        title={i18n.t("bonus_reward.notes")}
                        className='w-full'
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-y-3 gap-x-4 mt-[15px]">
                      <DateInput
                        model={model}
                        name="bonus_date"
                        title={i18n.t("bonus_reward.bonus_date")}
                        classStyle='w-full'
                        requiredField={true}
                      />

                      <DateInput
                        model={model}
                        name="payment_date"
                        title={i18n.t("bonus_reward.payment_date")}
                        classStyle='w-full'
                        requiredField={true}
                      />

                      <SelectInput
                        model={model}
                        name="payment_type"
                        options={paymentTypes}
                        title={i18n.t("bonus_reward.payment_type")}
                        className='w-full'
                        requiredField={true}
                      />
                    </div>
                  </div>

                  <div
                    id='bonus_forms_buttons'
                    className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
                  >
                    <div className='absolute bottom-[78px] right-2.5 left-2.5'>
                      <DivisionLine customStyle='mb-2.5' />
                      <CancelButton
                        buttonId={'bonus_reward_cancel_button'}
                        defaultText={i18n.t('buttons.cancel')}
                        onClick={() => { closeBonusDrawer(), openNewDrawer?.() }}
                        disabled={submittingData}
                        imagePresent={false}
                        innerDivClass={'grid'}
                        type={'button'}
                        buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-56' : 'right-[274px]'}`}
                      />

                      <SaveButton
                        form="bonus_reward_form"
                        defaultText={i18n.t(`${editObjectId ? 'bonus_reward_update' : 'bonus_reward_create'}.submit_button_text`)}
                        submittingData={submittingData}
                        processingText={i18n.t("buttons.saving")}
                        buttonId={"save_bonus_reward_button"}
                        buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-[165px]' : 'w-[220px]'}`}
                        type={'submit'}
                      />
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
