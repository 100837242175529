/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Menu } from '@headlessui/react'
import i18n from '#translate/i18n'
import moment from "moment"
import { yupResolver } from '@hookform/resolvers/yup'

import { UserContext } from "#providers/UserContextProvider"

import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from '#components/formComponents/DivisionLine'
import TextAreaInput from '#components/formComponents/TextAreaInput'
import DateInput from '#components/formComponents/DateInput'
import CurrencyInput from '#components/formComponents/CurrencyInput'
import SelectInput from '#components/formComponents/SelectInput'
import InputText from '#components/formComponents/InputText'
import ErrorMessage from '#components/messages/ErrorMessage'

import createOrUpdateVacation from '#requests/finances/allowances/createOrUpdateVacation'
import fetchAllowance from '#requests/userProfile/editions/fetchAllowance'

import addVacationSchema from '#validations/addVacationSchema'
import { vacationData as initialValues } from '#data/vacationData'
import fillBasicFormsFields from '#services/fillBasicFormsFields'
import formatDate from "#services/formatDate"

import menuArrow from '#images/add_allowances/menu_arrow.svg'

export default function VacationDrawer({
  closeAddAllowancesDrawer,
  registeredAllowances,
  user,
  userName,
  openNewDrawer,
  setShowMessage,
  setEditObjectId,
  editObjectId,
}) {
  const formName = 'vacation_form'
  const model = 'vacation'
  const [chosenAddBonus, setChosenAddBonus] = useState('')
  const [bonusPercentage, setBonusPercentage] = useState('')
  const [chosenYearRef, setChosenYearRef] = useState('')
  const [availableDaysOptions, setAvailableDaysOptions] = useState([''])
  const [creationErrors, setCreationErrors] = useState()
  const [submittingData, setSubmittingData] = useState(false)

  let vacationAttributes = registeredAllowances.find(({ name }) => name === 'vacation')['attributes']

  const [yearsOptions, setYearsOptions] = useState(vacationAttributes['years_options'])
  const [firstYear, setFirstYear] = useState('')
  const [secondYear, setSecondYear] = useState('')

  const firstYearAvailable = yearsOptions?.find(({ year }) => +year === firstYear)?.['available']
  const secondYearAvailable = yearsOptions?.find(({ year }) => +year === secondYear)?.['available']
  const firstYearOption = firstYearAvailable ? firstYear : `${firstYear} - ${i18n.t(`vacation.period_limit`)}`
  const secondYearOption = secondYearAvailable ? secondYear : `${secondYear} - ${i18n.t(`vacation.period_limit`)}`

  const { refreshUserData, setRefreshUserData } = useContext(UserContext)

  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);
  const [editData, setEditData] = useState('');
  const startedPastMonth = editData ? moment(editData.start_date, "YYYY-MM-DD").date(1) < moment().date(1) : false
  const startThisMonth = editData ? (moment(editData.start_date, "YYYY-MM-DD").date(1).startOf("day") === moment().date(1).startOf("day")) : false
  const disableAfterFirstDayOfMonth = !editData ? false : (startedPastMonth || (startThisMonth && moment().date() > 1))
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(addVacationSchema)
  })

  const buildDaysOptions = () => {
    if (!chosenYearRef) { return 0 }

    const yearAttributes = editData ? { ...editData[`edition_${chosenYearRef}_data`] } : { ...vacationAttributes[`${chosenYearRef}`] }
    if (!yearAttributes) { return 0 }

    const options = []
    const currentAvaliableDays = Number(yearAttributes['current_available_days'])
    const maxBusinessDays = Number(vacationAttributes['max_business_days'])
    const bonusDays = Number(vacationAttributes['additional_bonus_days'])
    const maxAvailable = [...Array(maxBusinessDays).keys()]
    maxAvailable.shift()
    const partialAvailableDays = [...Array(currentAvaliableDays + 1).keys()]
    partialAvailableDays.shift()
    const allAvailable = currentAvaliableDays === (maxBusinessDays + bonusDays)
    const availableDays = allAvailable ? maxAvailable : partialAvailableDays
    availableDays.forEach((value) => options.push(value))
    if (allAvailable) {
      options.push(`${(Number(maxBusinessDays)) + (Number(bonusDays))}`)
      availableDays.push(`${maxBusinessDays} + ${bonusDays} (bônus)`)
    }
    setAvailableDaysOptions(options)
    return availableDays.pop()
  }

  const handleAddBonusChange = (event) => {
    setChosenAddBonus(event.target.value)
    handleBonusValue()
  }

  const handleBonusValue = () => {
    const requestedDays = +formMethods.getValues('vacation.total_business_days')
    const maxBusinessDays = +formMethods.getValues('vacation.max_business_days')
    const bonusDays = +formMethods.getValues('vacation.additional_bonus_days')
    const maxSalaryPercentage = formMethods.getValues('vacation.max_salary_percentage')
    const dailySalaryPercentage = formMethods.getValues('vacation.daily_salary_percentage')
    const chosenMaxAmount = requestedDays === (maxBusinessDays + bonusDays)
    const currentSalaryPercentage = chosenMaxAmount ? maxSalaryPercentage : (requestedDays * dailySalaryPercentage)
    const currentBonusValue = ((user.salary * 100) * (currentSalaryPercentage) / 10000)
    setBonusPercentage(`${currentSalaryPercentage}%`)
    formMethods.setValue('vacation.bonus_value', currentBonusValue)
  }

  const calculateEditMinDate = ({ editionStartDate }) => {
    const sameMonth = editionStartDate.getMonth() === todayStart.getMonth()
    const sameYear = editionStartDate.getYear() === todayStart.getYear()
    const isSameMonth = sameMonth && sameYear
    let result = ''

    if ((isSameMonth && todayStart.getDate() > 1) || (editionStartDate < todayStart)) {
      result = editionStartDate
    } else {
      result = todayStart
    }
    return result
  }

  const loadData = ({ reset: setForm }) => {
    if (!vacationAttributes) { return }

    const relativeData = editData ? { ...editData } : { ...vacationAttributes }

    if (!editData) {
      relativeData['bonus_added'] = false
      relativeData['salary_percentage'] = ''
      relativeData['bonus_value'] = ''
      relativeData['total_business_days'] = ''
    }
    const availableDays = buildDaysOptions()
    relativeData['current_available_days'] = availableDays
    if (editData && Number(relativeData['total_business_days']) > availableDays) {
      relativeData['total_business_days'] = ''
    }
    if (chosenYearRef) {
      const vacations = editData ? relativeData[`edition_${chosenYearRef}_data`]['vacations'] : relativeData[`${chosenYearRef}`]['vacations']
      const firstId = editData ? vacations[0]?.['id'] : ''
      const secondId = editData ? vacations[1]?.['id'] : ''
      const firstVacation = vacations[0]
      const secondVacation = vacations[1]
      const filteredFirstVacation = (!firstId || firstId !== editObjectId) ? firstVacation : ''
      const filteredSecondVacation = (!secondId || secondId !== editObjectId) ? secondVacation : ''

      relativeData['first_start_date'] = filteredFirstVacation?.['start_date'] || ''
      relativeData['first_return_date'] = filteredFirstVacation?.['return_date'] || ''
      relativeData['last_start_date'] = filteredSecondVacation?.['start_date'] || ''
      relativeData['last_return_date'] = filteredSecondVacation?.['return_date'] || ''
      relativeData['previous_added_bonus'] = editData ? relativeData[`edition_${chosenYearRef}_data`]['previous_added_bonus'] : relativeData[`${chosenYearRef}`]['previous_added_bonus']

      let chosenYear = chosenYearRef === 'first_year' ? firstYear : secondYear
      const firstYearVacations = editData ? relativeData['edition_first_year_data']['vacations'] : relativeData['first_year']['vacations']
      const firstYearLastDate = fetchFirstYearLastDate({ vacations: firstYearVacations })
      const secondYearBeginning = new Date(secondYear, 0, 1)
      const secondYearMin = fetchSecondYearStartDate(firstYearLastDate, secondYearBeginning)
      const minDate = chosenYearRef === 'first_year' ? todayStart : secondYearMin
      const editionStartDate = editData ? new Date(`${relativeData['start_date']}T00:00`) : ''
      let globalMinDate = ''
      if (editionStartDate) {
        globalMinDate = calculateEditMinDate({ editionStartDate: editionStartDate })
      } else {
        globalMinDate = minDate < todayStart ? todayStart : minDate
      }

      relativeData['min_date'] = formatDate(globalMinDate)
      relativeData['max_date'] = formatDate(new Date(chosenYear + 1, 6, 31))
      relativeData['reference_year'] = chosenYear
    }
    fillBasicFormsFields({ setForm: setForm, response: { vacation: { ...vacationAttributes, ...relativeData } } })
    if (relativeData['bonus_added']) {
      setChosenAddBonus(String(relativeData['bonus_added']))
      handleBonusValue()
    }
  }

  const fetchFirstYearLastDate = ({ vacations }) => { // the ones with start date up to first_year december 31
    const firstVacation = vacations[0]
    const secondVacation = vacations[1]
    if (!firstVacation && !secondVacation) { return '' }
    const firstStartDate = new Date(`${firstVacation['start_date']}T00:00`)
    const secondStartDate = secondVacation ? new Date(`${secondVacation['start_date']}T00:00`) : null
    const firstYearStartDatePresent = firstStartDate.getFullYear() === firstYear || secondStartDate?.getFullYear() === firstYear
    if (firstYearStartDatePresent) {
      const lastDay = secondStartDate?.getFullYear() === firstYear ? secondVacation?.['return_date'] : firstVacation['return_date']
      return new Date(`${lastDay}T00:00`)
    } else {
      return ''
    }
  }

  const fetchSecondYearStartDate = (firstYearLastDate, secondYearBeginning) => {
    if (!firstYearLastDate) { return secondYearBeginning }
    if (firstYearLastDate > secondYearBeginning) { return firstYearLastDate }
    return secondYearBeginning
  }

  const buildItemStyle = (enabled) => {
    const itemStyle = 'text-left h-[100%] w-[100%] font-sans not-italic font-medium text-sm leading-[2px] z-[1600]'
    return `${itemStyle} ${enabled ? 'text-default-gray-2' : 'text-placeholder-gray'}`
  }

  const submitData = (data) => {
    setSubmittingData(true)
    createOrUpdateVacation({ data: data, model: model, user: user, objectId: editObjectId })
      .then(() => {
        closeAddAllowancesDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'vacation_updated' : 'vacation_created'
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        setRefreshUserData(!refreshUserData)
        openNewDrawer?.()
      }).catch(function (requestReturn) {
        setSubmittingData(false)
        setCreationErrors(requestReturn.response.data.errors)
        formMethods.setError(`${model}.start_date`, { type: 'custom', message: i18n.t('vacation.messageForm.error.basic_server_error') })
      })
  }

  const shouldShowUserNameFunction = (message) => {
    const messagesWithoutUserName = [
      "não_é_permitido_criar_um_descanso_remunerado_após_o_primeiro_dia_do_mês_solicitado",
      "não_é_possível_atualizar_a_data_de_início_para_o_mês_corrente_se_o_dia_de_atualização_não_for_o_primeiro_dia_do_mês"
    ]
    return !messagesWithoutUserName.includes(message)
  }

  useEffect(() => {
    loadData(formMethods)
  }, [chosenYearRef])

  useEffect(() => {
    formMethods.setValue(`${model}.start_date_past`, false)

    if (editObjectId) {
      fetchAllowance({ id: editObjectId, user: user, allowanceName: 'vacation' })
        .then((parsedResponse) => {
          const data = parsedResponse.data

          const pastStart = new Date(`${data['start_date']}T00:00`) < todayStart
          formMethods.setValue(`${model}.start_date_past`, pastStart)
          let filteredData = { ...data }
          Object.keys(filteredData).forEach(function (key) {
            if (filteredData[key] === null) {
              filteredData[key] = '';
            }
          })
          setEditData({ start_date_past: pastStart, ...filteredData })
        })
    } else {
      setFirstYear(yearsOptions[0].year)
      setSecondYear(yearsOptions[1].year)
    }
  }, [])

  useEffect(() => {
    if (editData) {
      setTimeout(() => {
        setFirstYear(editData.edition_first_year_data.year)
        setSecondYear(editData.edition_second_year_data.year)
        const newYearsOptions = [
          { year: editData.edition_first_year_data.year, available: editData.edition_first_year_available },
          { year: editData.edition_second_year_data.year, available: editData.edition_second_year_available }
        ]
        setYearsOptions(newYearsOptions)
        const fetchChosenYear = editData.edition_first_year_data.year === editData.reference_year ? 'first_year' : 'second_year'
        setChosenYearRef(fetchChosenYear)
      }, 300);
    }
  }, [editData]);

  return (
    <div
      id='vacation_forms_div'
      className='overflow-y-auto max-h-[70vh] bg-white absolute
                sm:top-[220px] md:top-[230px] lg:top-[255px] xl:top-[275px] 2xl:top-[310px] 2k-monitor:top-[345px]
                bottom-[100px] lg:bottom-[105px] xl:bottom-[100px]
                right-0 left-0 mx-6 px-0.5'
    >
      {creationErrors && (
        <div className='grid grid-cols-1 mt-2'>
          <ErrorMessage
            errors={creationErrors}
            removeErrors={setCreationErrors}
            model={model}
            userName={userName}
            className={'mt-0'}
            shouldShowUserNameFunction={shouldShowUserNameFunction}
          />
        </div>
      )}
      <p className='font-sans not-italic font-medium text-sm text-title-gray mb-1.5'>
        Ano de referência
      </p>
      <Menu as='div' className='relative z-50 mb-4' id='vacation_years_options'
      > 
        <Menu.Button
          className='bg-wthite relative flex flex_-row items-center py-2.5 px-3.5 gap-2 h-11 w-full
          border border-solid border-border-gray rounded box-border order-1 hover:border-border-blue 
          disabled:bg-border-gray disabled:text-disabled-dark-gray'
          name='handle_vacation_year_option_button'
          data-testid='handle_vacation_year_option_button'
          disabled={disableAfterFirstDayOfMonth}
          id='handle_vacation_year_option_button'
        >
          {
            chosenYearRef ?
              <div className='font font-sans font-medium text-sm text-base text-disabled-dark-gray flex flex-row'>
                <p>{chosenYearRef === 'first_year' ? `${firstYear}` : `${secondYear}`}</p>
              </div>
              : <p> {i18n.t('vacation.year_option')} </p>
          }
          <img
            className='absolute right-2'
            src={menuArrow}
            alt='Arrow'
          />
        </Menu.Button>

        <div id='years_options_list'>
          <Menu.Items
            className='absolute rounded bg-white grid grid-cols-1 ml-0 mt-1 w-full z-[1600]'
          >
            <div id='years_options_items'
              className='py-1 relative border border-solid border-border-blue rounded'
            >
              <Menu.Item
                as="button"
                className={`${buildItemStyle(firstYearAvailable)} block p-4 text-sm h-[45px] w-full hover:bg-light-gray z-[1600]`}
                disabled={!firstYearAvailable}
                id='vacation_current_year_option_button'
                onClick={() => setChosenYearRef('first_year')}
              >
                {firstYearOption}
              </Menu.Item>
              <Menu.Item
                as="button"
                className={`${buildItemStyle(secondYearAvailable)} block p-4 text-sm') gap-3 h-[45px] w-full hover:bg-light-gray`}
                disabled={!secondYearAvailable}
                id='vacation_next_year_option_button'
                onClick={() => setChosenYearRef('second_year')}
              >
                {secondYearOption}
              </Menu.Item>
            </div>
          </Menu.Items>
        </div>
      </Menu>

      <FormProvider {...formMethods}>
        <form
          id={formName}
          onSubmit={formMethods.handleSubmit(submitData)}
          className='z-0'
        >

          <div id='vacation_forms_fields' className={chosenYearRef ? '' : 'w-0 h-0'}>
             <div className='grid grid-cols-2 gap-y-3 gap-x-4 mb-[15px]'>
              <InputText
                model={model}
                title={i18n.t('vacation.current_available_days')}
                name='current_available_days'
                disabled
                placeholder={i18n.t('vacation.current_available_days_placeholder')}
                className='w-full'
                shouldHide={chosenYearRef ? false : true}
              />
              <SelectInput
                model={model}
                title={i18n.t('vacation.total_business_days_title')}
                name='total_business_days'
                disabled={disableAfterFirstDayOfMonth}
                optionsI18nPrefix={model}
                options={availableDaysOptions}
                onChange={handleBonusValue}
                className='w-full'
                shouldHide={chosenYearRef ? false : true}
                requiredField={true}
              />
              <DateInput
                model={model}
                name='start_date'
                title={i18n.t('vacation.start_date')}
                classStyle='w-full'
                disabled={editData?.start_date_past || disableAfterFirstDayOfMonth }
                shouldHide={chosenYearRef ? false : true}
                requiredField={chosenYearRef ? true : false}
              />
              <DateInput
                model={model}
                name='return_date'
                title={i18n.t('vacation.return_date')}
                disabled={disableAfterFirstDayOfMonth}
                classStyle='w-full'
                shouldHide={chosenYearRef ? false : true}
                requiredField={chosenYearRef ? true : false}
              />
            </div>
            <div className='grid grid-cols-1 space-y-5 gap-x-4 mb-[15px]'>
              <SelectInput
                model={model}
                name='bonus_added'
                optionsI18nPrefix={model}
                options={['false', 'true']}
                disabled={formMethods.getValues('vacation.previous_added_bonus') || disableAfterFirstDayOfMonth}
                title={i18n.t('vacation.bonus_added_title')}
                onChange={handleAddBonusChange}
                inputstyle={formMethods.getValues('vacation.previous_added_bonus') ? { color: 'rgb(148 163 184)', backgroundColor: 'rgb(228 228 231)', opacity: 1 } : {}}
                className='w-full'
                shouldHide={chosenYearRef ? false : true}
              />
            </div>
            <div id='vacation_bonus_amount_data' className={`grid grid-cols-2 gap-y-3 gap-x-4 mb-[15px] ${chosenAddBonus === 'true' ? '' : 'hidden'}`}>
              <InputText
                model={model}
                name='salary_percentage'
                disabled
                shouldSetValue={formMethods.getValues('vacation.total_business_days') ? true : false}
                valuetoset={bonusPercentage}
                title={i18n.t('vacation.salary_percentage')}
                className='w-full'
                shouldHide={chosenYearRef ? false : true}
              />
              <CurrencyInput
                model={model}
                name='bonus_value'
                disabled
                title={i18n.t('vacation.bonus_value')}
                className='w-full'
                shouldHide={chosenYearRef ? false : true}
              />
            </div>
            <div className='grid grid-cols-1 space-y-5 mb-2.5'>
              <TextAreaInput
                model={model}
                name='notes'
                placeholder={i18n.t('vacation.notes_placeholder')}
                title={i18n.t('vacation.notes')}
                className='w-full'
                shouldHide={chosenYearRef ? false : true}
              />
            </div>
          </div>
          <input type='hidden' id={`${model}_first_start_date_input`} {...formMethods.register(`${model}.first_start_date`)} />
          <input type='hidden' id={`${model}_first_return_date_input`} {...formMethods.register(`${model}.first_return_date`)} />
          <input type='hidden' id={`${model}_last_start_date_input`} {...formMethods.register(`${model}.last_start_date`)} />
          <input type='hidden' id={`${model}_last_return_date_input`} {...formMethods.register(`${model}.last_return_date`)} />
          <input type='hidden' id={`${model}_min_date_input`} {...formMethods.register(`${model}.min_date`)} />
          <input type='hidden' id={`${model}_max_date_input`} {...formMethods.register(`${model}.max_date`)} />
          <input type='hidden' id={`${model}_reference_year`} {...formMethods.register(`${model}.reference_year`)} />
          {!chosenYearRef && <div className='h-[150px] absolute top-[250px] z-[1200]' />}
          <div id='vacation-forms-buttons'
            className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
          >
            <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
              <DivisionLine customStyle='mb-2.5' />
              <CancelButton
                buttonId={'vacation-forms-cancel-button'}
                defaultText={i18n.t('buttons.cancel')}
                onClick={() => { closeAddAllowancesDrawer(), openNewDrawer?.() }}
                disabled={submittingData}
                imagePresent={false}
                innerDivClass={'grid'}
                type={'button'}
                buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[275px]'}`}
              />

              <SaveButton
                form={formName}
                defaultText={i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.submit_button_text`)}
                submittingData={submittingData}
                processingText={i18n.t("buttons.saving")}
                buttonId={"vacation-forms-submit-button"}
                buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-44' : 'w-[214px]'}`}
                type={'submit'}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
