import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import i18n from "#translate/i18n";
import PeopleImage from "#images/expense_assistance_drawer/header_people.svg";
import CoinsImage from "#images/expense_assistance_drawer/coins.svg";
import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from "#components/formComponents/DivisionLine";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import DateInput from "#components/formComponents/DateInput";
import SelectInput from "#components/formComponents/SelectInput";
import createOrUpdateExpenseAssistance from "#requests/finances/createOrUpdateExpenseAssistance";
import fetchExpenseAssistance from "#requests/userProfile/editions/fetchExpenseAssistance";
import fetchPaymentsAssistancesDurations from "#requests/finances/fetchPaymentsAssistancesDurations";
import expenseAssistanceSchema from "#validations/expenseAssistanceSchema";
import { expenseAssistanceData as initialValues } from "#data/expenseAssistanceData";
import { yupResolver } from "@hookform/resolvers/yup";
import RecurringExpenseAssistance from "#components/finances/drawers/RecurringExpenseAssistance"
import SingleExpenseAssistance from "#components/finances/drawers/SingleExpenseAssistance"
import useOnClickOutside from '#services/useOnClickOutside';
import CloseButton from '#images/add_allowances/close_icon.svg';

export default function ExpenseAssistance({
  closeExpenseAssistanceDrawer,
  userName,
  user,
  openNewDrawer,
  setShowMessage,
  editObjectId,
  setEditObjectId
}) {
  const formName = "expense_assistance_form"
  const model = "expense_assistance"
  const [paymentTypes, setPaymentTypes] = useState(['']);
  const [frequencies, setFrequencies] = useState(['']);
  const [durations, setDurations] = useState(['']);
  const [chosenFrequency, setChosenFrequency] = useState('');
  const [editData, setEditData] = useState('');
  const [submittingData, setSubmittingData] = useState(false);
  const expenseAssistanceDrawer = useRef();
  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);

  useOnClickOutside({
    ref: expenseAssistanceDrawer,
    handler: () => { closeExpenseAssistanceDrawer(), openNewDrawer?.() },
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  });

  const expenseAssistanceAttr = ['frequency', 'payment_start_date', 'notes', 'category', 'reason',
    'payment_type', 'assistance_monthly_value', 'payment_end_date', 'duration',
    'payment_start_date_past']

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(expenseAssistanceSchema)
  });

  const currentMonthlyValue = formMethods.getValues(`${model}.assistance_monthly_value`)

  const customStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 16,
    gap: 12,
    height: 52,
    background: '#FFFFFF',
    fontFamily: 'Inter',
    fontSyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 52,
    color: '#485668'
  }

  useEffect(() => {
    fetchPaymentsAssistancesDurations()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setDurations(parsedResponse["durations"]);
        setPaymentTypes(parsedResponse["payment_types"]);
        setFrequencies(parsedResponse["frequencies"]);
        formMethods.setValue(`${model}.payment_start_date_past`, false)
      })
      .then(() => {
        if (editObjectId) {
          fetchExpenseAssistance({ id: editObjectId, user: user })
            .then((parsedResponse) => {
              const data = parsedResponse.data
              setChosenFrequency(data['frequency'])
              const pastStart = data && new Date(`${data['payment_start_date']}T00:00`) < todayStart
              setEditData({ payment_start_date_past: pastStart, ...data })
            })
        }
      })
  }, []);

  useEffect(() => {
    if (!editData) { return }
    setTimeout(() => {
      expenseAssistanceAttr.forEach(element => {
        if (element !== 'duration') {
          formMethods.setValue(`${model}.${element}`, editData[element] === null ? "" : editData[element])
        } else {
          const duration = editData['duration'] === null ? 'undetermined_time' : Number(editData[element])
          formMethods.setValue(`${model}.${element}`, duration === 1 ? '' : duration)
        }
      });

      const validDurationsOptions = durations.filter((durationOption) => durationOption === 'undetermined_time' || Number(durationOption) > Number(editData.paid_installments))
      setDurations(validDurationsOptions)
    }, 300);
  }, [editData]);

  useEffect(() => {
    if (!chosenFrequency) { return }
    formMethods.setValue(`${model}.assistance_monthly_value`, currentMonthlyValue)
  }, [chosenFrequency]);

  function handleAssistanceTypeChange(event) {
    setChosenFrequency(event.target.value)
  }

  const onSubmit = (data) => {
    setSubmittingData(true)
    createOrUpdateExpenseAssistance({ data: data, model: model, user: user, expenseId: editObjectId })
      .then(() => {
        closeExpenseAssistanceDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'expense_assistance_updated' : 'expense_assistance_created'
        }
        setShowMessage(messages => [newMessage, ...messages])        
        openNewDrawer?.()
        setEditObjectId?.(null)
      }).catch(function (error) {
        setSubmittingData(false)
        throw new Error(error);
      })
  };

  return (
    <>
      <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen'>
        <div
          ref={expenseAssistanceDrawer}
          className='bg-white absolute right-0 top-0 bottom-0 rounded-none w-1/2
                     shadow-[0px_16px_24px_rgb(0,0,0,0.14),0px_6px_30px_rgb(0,0,0,0.12)'
          id='expense-assistance-drawer'
        >
          <div className="block fixed right-0 left-1/2 bottom-0 top-0">
            <div className="block h-fit" >
              <div className={`${editObjectId ? 'bg-complementary-yellow' : 'bg-rebase-green'} z-1600 flex
                              justify-center h-[90px]md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px]
                              max-h-[15vh]`}
              >
                <img
                  className="text-center h-[95%] self-end"
                  src={PeopleImage}
                  alt={i18n.t("expenseAssistanceDrawer.peopleHeader")}
                />
                <button
                  id='close_expense_drawer_button'
                  type='button'
                  className='absolute top-5 right-5'
                  onClick={() => { closeExpenseAssistanceDrawer(), openNewDrawer?.() }}
                >
                  <img
                    id='close_selection_drawer_image'
                    src={CloseButton}
                    alt={i18n.t('buttons.logout')}
                    className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                  />
                </button>
              </div>
              <div className='m-4 flex' >
                <div className="mt-0 mr-4 z-[1650] bg-white" >
                  <img
                    src={CoinsImage}
                    alt={i18n.t("expenseAssistanceDrawer.coins")}
                  />
                </div>
                <div className="mt-0 z-[1600]" >
                  <h5 className="font-sans not-italic font-bold text-xl/6" >
                    {editObjectId ? i18n.t("update_expense_assistance.title") : i18n.t("create_expense_assistance.title")}
                  </h5>
                  <h6 className="font-sans not-italic text-sm/6" >
                    <p className='mt-[5px]' >
                      {editObjectId ? i18n.t("update_expense_assistance.subtitle") : i18n.t("create_expense_assistance.subtitle")} <span className='font-semibold' >{userName}</span>.
                    </p>
                  </h6>
                </div>
              </div>
            </div>
            <div className="block max-h-[85vh] px-6 pb-2.5 pt-0 mt-0 sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0"
            >
              <FormProvider {...formMethods}>
                <form
                  id={formName}
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                  <div
                    className="overflow-y-auto max-h-[70vh] absolute right-0 left-0 mx-6 px-0.5
                               sm:top-[165px] md:top-[165px] lg:top-[180px] xl:top-[191px] 2xl:top-[226px] 2k-monitor:top-[261px]
                               sm:bottom-[100px] md:bottom-[100px] lg:bottom-[105px] xl:bottom-[105px] 2xl:bottom-[100px]"
                  >
                    <div className="grid grid-cols-2 gap-y-3 gap-x-4 mt-1" >
                      <SelectInput
                        model={model}
                        name="frequency"
                        options={frequencies}
                        title={i18n.t("expense_assistance.frequency_title")}
                        onChange={handleAssistanceTypeChange}
                        optionsI18nPrefix={model}
                        liststyle={customStyles}
                        className='w-full'
                        disabled={editData?.payment_start_date_past === true ? true : false}
                        requiredField={true}
                      />

                      <SingleExpenseAssistance
                        paymentTypes={paymentTypes}
                        user={user}
                        model={model}
                        customStyles={customStyles}
                        chosenFrequency={chosenFrequency}
                      />

                      <RecurringExpenseAssistance
                        paymentTypes={paymentTypes}
                        durations={durations}
                        user={user}
                        model={model}
                        customStyles={customStyles}
                        chosenFrequency={chosenFrequency}
                      />
                    </div>

                    <div className={`grid grid-cols-1 space-y-5 mt-3 ${chosenFrequency === 'recurring' ? "" : "hidden"}`}>
                      <DateInput
                        model={model}
                        disabled={editData?.payment_start_date_past === true ? true : false}
                        name={`${chosenFrequency === 'recurring' ? "payment_start_date" : "payment_start_date_hidden"}`}
                        title={i18n.t("expense_assistance.payment_start_date")}
                        classStyle='w-full'
                        requiredField={true}
                      />
                    </div>

                    <div className="grid grid-cols-1 space-y-5">
                      <div className='pb-7 mt-4' >
                        <TextAreaInput
                          model={model}
                          name="notes"
                          placeholder={i18n.t("expense_assistance.notes_placeholder")}
                          title={i18n.t("expense_assistance.notes")}
                          className='w-full'
                        />
                      </div>
                    </div>
                  </div>
                  <div id='expense_assistance_forms_buttons' className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]' >
                    <div className='absolute right-2.5 left-2.5 bottom-[78px]' >
                      <DivisionLine customStyle='mb-2.5' />
                      <CancelButton
                        buttonId={'expense_assistance_cancel_button'}
                        defaultText={i18n.t('buttons.cancel')}
                        onClick={() => { closeExpenseAssistanceDrawer(), openNewDrawer?.() }}
                        disabled={submittingData}
                        imagePresent={false}
                        innerDivClass={'grid'}
                        buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-[230px]' : 'right-[300px]'}`}
                        type={'button'}
                      />

                      <SaveButton
                        form="expense_assistance_form"
                        defaultText={i18n.t(`${editObjectId ? 'update_expense_assistance' : 'create_expense_assistance'}.submit_button`)}
                        submittingData={submittingData}
                        processingText={i18n.t("buttons.saving")}
                        buttonId={"save_expense_assistance_button"}
                        buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-[170px]' : 'w-64'}`}
                        type={'submit'}
                      />
                    </div>
                  </div>
                  <input type='boolstringean' className="w-0 h-0" id={`${model}_payment_start_date_past`} {...formMethods.register(`${model}.payment_start_date_past`)} />
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
