/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import moment from "moment"
import i18n from '#translate/i18n';
import capitalizeFirstLetter from "#services/capitalizeFirstLetter"
import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from '#components/formComponents/DivisionLine';
import DateCustomInput from "#components/formComponents/DateCustomInput";
import TextAreaInput from '#components/formComponents/TextAreaInput';
import HiddenInput from "#components/formComponents/HiddenInput";

import createOrUpdatePsychologicalSupport from '#requests/finances/allowances/createOrUpdatePsychologicalSupport';
import fetchAllowance from '#requests/userProfile/editions/fetchAllowance'

import addPsychologicalSupportSchema from '#validations/addPsychologicalSupportSchema';
import { psychologicalSupportData as initialValues } from '#data/psychologicalSupportData';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectInput from "#components/formComponents/SelectInput";
import PsychologicalSupportSessionsInput from './PsychologicalSupportSessionsInput'

export default function PsychologicalSupportDrawer({
  closeAddAllowancesDrawer,
  registeredAllowances,
  user,
  userName,
  openNewDrawer,
  setShowMessage,
  setEditObjectId,
  editObjectId,
}) {
  const formName = 'psychological_support_form'
  const model = 'psychological_support'
  const [submittingData, setSubmittingData] = useState(false)
  const [editData, setEditData] = useState();
  const currentAllowance = registeredAllowances.find(({ name }) => name === 'psychological_support')
  const [durations, setDurations] = useState(currentAllowance?.attributes?.durations || ['']);
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(addPsychologicalSupportSchema)
  });
  const psychologicalSupportAttr = ['notes', 'monthly_sessions', 'duration', 'sessions_start_date']

  const submitData = (data) => {
    setSubmittingData(true)
    createOrUpdatePsychologicalSupport({ data: data, user: user, model: model, objectId: editObjectId })
      .then(() => {
        closeAddAllowancesDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'psychological_support_updated' : 'psychological_support_created'
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        openNewDrawer?.()
      }).catch(function (error) {
        setSubmittingData(false)
        throw new Error(error);
      })
  };

  useEffect(() => {
    if (editObjectId) {
      fetchAllowance({ id: editObjectId, user: user, allowanceName: 'psychological_support' })
        .then((parsedResponse) => {
          const data = parsedResponse.data
          setEditData(data)
          psychologicalSupportAttr.forEach(element => {
            if (element !== 'duration') {
              formMethods.setValue(`${model}.${element}`, data[element])
            } else {
              const duration = data['duration'] === null ? 'undetermined_time' : Number(data[element])
              formMethods.setValue(`${model}.${element}`, duration)
            }
          });
          const startDate = moment(data.sessions_start_date, 'YYYY-MM-DD').startOf('month')
          if(startDate < moment().startOf('month')) {
            formMethods.setValue(`${model}.min_sessions_start_date`, startDate.startOf('month'))
          } else {
            formMethods.setValue(`${model}.min_sessions_start_date`, moment().startOf('month'))
          }

          const durationRefDate = moment().startOf('month')
          const concludedMonthsCount = durationRefDate.diff(startDate, 'months')

          const validDurationsOptions = durations.filter((durationOption) => durationOption === 'undetermined_time' || Number(durationOption) > Number(concludedMonthsCount))
          setDurations(validDurationsOptions)
        })
    } else {
      formMethods.setValue(`${model}.min_sessions_start_date`, moment().startOf('month'))
    }
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form
        id={formName}
        className='overflow-y-auto max-h-[70vh] bg-white absolute
                   sm:top-[220px] md:top-[230px] lg:top-[255px] xl:top-[275px] 2xl:top-[310px] 2k-monitor:top-[345px]
                   bottom-[100px] lg:bottom-[105px] xl:bottom-[100px]
                   right-0 left-0 mx-6 px-0.5'
        onSubmit={formMethods.handleSubmit(submitData)}
      >
        <div className='grid grid-cols-1 gap-y-3 gap-x-4'>
          <PsychologicalSupportSessionsInput
            model={model}
            name='monthly_sessions'
            currentAllowance={currentAllowance}
            user={user}
          />
          <DateCustomInput
            fieldId={`${model}_sessions_start_date_input`}
            model={model}
            requiredField={true}
            name={"sessions_start_date"}
            title={i18n.t(`${model}.sessions_start_date`)}
            dateFormat="MM/yyyy"
            placeholderText={'mm/aaaa'}
            showMonthYearPicker
            dateFormatCalendar={"MM/yyyy"}
            className={"disabled:bg-border-gray disabled:text-placeholder-gray flex items-center"}
            divClassName={"py-2 mt-2 text-md block w-full appearance-none border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11 flex items-center"}
            titleClass={'font font-medium font-Inter leading-5 text-sm text-gray-700'}
            renderMonthContent={(month, shortMonth, longMonth, day) => {
              const fullYear = moment(day).year();
              const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

              return <span title={tooltipText}>{capitalizeFirstLetter(shortMonth)}</span>;
            }}
            disabled={editData && moment(editData.sessions_start_date, "YYYY-MM-DD").endOf('day') < moment().endOf('day')}
          />
          <SelectInput
            model={model}
            name="duration"
            options={durations}
            title={i18n.t("psychological_support.duration.title")}
            optionsI18nPrefix={model}
            showDefaultFirstOption={false}
            className='w-full'
            requiredField={true}
          />
          <HiddenInput model={model} name={`min_sessions_start_date`} id={`${model}_min_sessions_start_date_input`} />
        </div>
        <div className='grid grid-cols-1 gap-y-3 gap-x-4 mt-2 mb-5'>
          <TextAreaInput
            model={model}
            name='notes'
            placeholder={i18n.t('psychological_support.notes_placeholder')}
            title={i18n.t('psychological_support.notes')}
            className='w-full'
          />
        </div>
        <div id='psychological-support-forms-buttons'
          className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
        >
          <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
            <DivisionLine customStyle='mb-2.5' />
            <CancelButton
              buttonId={'psychological-support-forms-cancel-button'}
              defaultText={i18n.t('buttons.cancel')}
              onClick={() => { closeAddAllowancesDrawer(), openNewDrawer?.() }}
              disabled={submittingData}
              imagePresent={false}
              innerDivClass={'grid'}
              type={'button'}
              buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[275px]'}`}
            />

            <SaveButton
              form={formName}
              defaultText={i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.submit_button_text`)}
              submittingData={submittingData}
              processingText={i18n.t("buttons.saving")}
              buttonId={"psychological-support-forms-submit-button"}
              buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-44' : 'w-[214px]'}`}
              type={'submit'}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
