/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useContext } from 'react';
import i18n from '#translate/i18n';
import { UserContext } from "#providers/UserContextProvider"
import ReturnButton from '#images/profile_drawer/arrow-left.svg';
import ImgProfile from "#images/profile_picture.svg";
import BackgroundIcon from "#images/background_icon.svg";
import BackgroundAlternateIcon from "#images/background_alternate_icon.svg";
import useOnClickOutside from '#services/useOnClickOutside';
import userPresentedName from "#services/userPresentedName";
import ProfileBackground from "#components/user/profile/ProfileBackground";
import ProfileInfo from "#components/user/profile/ProfileInfo";
import Socials from "#components/user/profile/Socials"
import userProfile from "#requests/userProfile";
import { UploadFacePicture, UploadBackground } from "#components";
import LoadingPage from "#components/navigation/LoadingPage";
import Menu from "#components/listings/person/Menu"
import Tabs from '#components/user/profile/Tabs';
import RoundPicture from "#components/user/RoundPicture";
import Status from "#components/listings/person/Status"

export default function ProfileDrawer({
  user,
  setOpenedProfileDrawer,
  authenticatedUser
}) {
  const {
    refreshUserData,
    setRefreshUserData,
    handleDrawersOpen,
    handleDrawersClose,
    setMenuOrigin,
    setProfileTabName,
  } = useContext(UserContext)

  const profileDrawer = useRef();
  const [userData, setUserData] = useState();

  function closeDrawers() {
    handleDrawersClose({ drawersToClose: [], clearData: false })
    setOpenedProfileDrawer?.(false)
  }

  useOnClickOutside({
    ref: profileDrawer,
    handler: () => {
      if (document.getElementById('profile_header')) {
        closeDrawers()
        setProfileTabName('')
      }
    },
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  });

  useEffect(() => {
    if(!user?.id) { return }

    async function refresh() {
      setMenuOrigin['profile'](true)
      const response = await userProfile(user.id);
      setUserData({ presentedName: userPresentedName(response), ...response });
    }
    refresh()
  }, [refreshUserData, user]);

  function displayProfileBackgroundPreview(preview) {
    setUserData((prevState) => ({
      ...prevState,
      current_profile_background_url: preview,
    }));
  }

  function displayProfilePicturePreview(preview) {
    setUserData((prevState) => ({ ...prevState, face_picture: { ...prevState.face_picture, url: preview } }));
  }

  function handleOpenedDrawersFromProfile(person, drawer) {
    setMenuOrigin['people'](false)
    setMenuOrigin['profile'](true)
    handleDrawersOpen(person, drawer)
  }
  if(!user.id) { return }

  return (
    <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen' id='userProfile'>
      <div
        ref={profileDrawer}
        className='absolute float-right bg-white right-0 top-0 bottom-0 rounded-none w-1/2 sm:w-[70%] 2xl:w-1/2
          h-screen shadow-[0px_16px_24px_rgb(0,0,0,0.14),0px_6px_30px_rgb(0,0,0,0.12)] overflow-hidden overflow-y-auto'
        id='profile-drawer'
      >
        {!userData ? <LoadingPage /> :
          <div id='profile_header' >
            <div className='group' id='user_background'>
              <ProfileBackground
                profileBackground={userData.current_profile_background_url}
                style={`bg-lighter-gray h-[222px] min-w-full ${userData.current_profile_background_url && 'group-hover:brightness-50'}`}
              />
              <UploadBackground
                style={`absolute ${userData.current_profile_background_url
                  ? 'right-[47%] top-24 opacity-0 group-hover:opacity-100'
                  : 'right-5 top-5'}`
                }
                displayProfileBackgroundPreview={displayProfileBackgroundPreview}
                userId={userData.id}
                src={userData.current_profile_background_url ? BackgroundAlternateIcon : BackgroundIcon}
                setUpdated={setRefreshUserData}
              />
            </div>

            <button
              id='close_selection_drawer_button'
              type='button'
              className='absolute top-5 left-5'
              onClick={() => {
                closeDrawers()
                setProfileTabName('')
              }}
            >
              <img
                id='close_profile_drawer_image'
                src={ReturnButton}
                alt={i18n.t('userProfile.return_icon')}
              />
            </button>
            <div
              className='group absolute left-6 top-[158px] w-32 h-32 bg-white rounded-full'
              id='user_profile_picture'
            >
              <RoundPicture
                divStyle="w-[120px] h-[120px] m-auto"
                imgStyle="group-hover:brightness-50 object-cover"
                id={`user-id-${user.id}`}
                alt="profile"
                src={userData.face_picture?.url ? userData.face_picture.url : ImgProfile}
              />
              <UploadFacePicture
                style={`absolute left-[46px] top-[47px] ${userData.face_picture?.url && 'opacity-0 group-hover:opacity-100'}`}
                displayProfilePicturePreview={displayProfilePicturePreview}
                userId={user.id}
                setUpdated={setRefreshUserData}
              />
            </div>

            <nav className="absolute pt-[27px] pb-[19px] right-5 flex" id="user_socials_and_menu">
              <Status person={userData} showMenu={false}/>
              <Socials companyHistory={userData.company_history} />
              {authenticatedUser.role === 'admin' &&
                <Menu
                  closeDrawers={() => { closeDrawers(), setMenuOrigin['profile'](true) }}
                  person={user}
                  handleDrawersOpen={handleOpenedDrawersFromProfile}
                  setProfileTabName={setProfileTabName}
                />
              }
            </nav>

            <div className="absolute top-[286px] pb-12 w-full">
              <div className="flex flex-col">
                <ProfileInfo userData={userData} />
                <Tabs
                  userData={userData}
                  handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                  closeDrawers={() => { closeDrawers(), setMenuOrigin['profile'](true) }}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
