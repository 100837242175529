/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import i18n from "#translate/i18n";
import moment from "moment"
import { yupResolver } from "@hookform/resolvers/yup";

import { UserContext } from "#providers/UserContextProvider"

import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from "#components/formComponents/DivisionLine";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import DateCustomInput from "#components/formComponents/DateCustomInput";
import SelectInput from "#components/formComponents/SelectInput";
import UploadFile from "#components/uploadFile/UploadFile";
import InputText from "#components/formComponents/InputText";
import CurrencyInput from '#components/formComponents/CurrencyInput';
import UserDismissalAccess from "./UserDismissalAccess";
import UserDismissalInstallments from "./UserDismissalInstallments";
import UserDismissalSelectBenefits from "./UserDismissalSelectBenefits";
import HiddenInput from "#components/formComponents/HiddenInput";

import userDismissalSchema from "#validations/userDismissalSchema";
import { dismissalData as initialValues } from "#data/dismissalData";

import useOnClickOutside from '#services/useOnClickOutside';
import isMonthBygone from "#services/isMonthBygone"

import CloseButton from '#images/add_allowances/close_icon.svg';
import docIcon from "#images/doc-icon.svg";
import PeopleImage from "#images/user_dismissal_drawer/header_people.svg";
import DocumentImage from "#images/user_dismissal_drawer/document.svg";

import fetchDismissalReasons from "#requests/dismissals/fetchDismissalReasons";
import createOrUpdateDismissal from '#requests/dismissals/createOrUpdateDismissal'
import fetchDismissalDetails from "#requests/userProfile/editions/fetchDismissalDetails";

export default function UserDismissal() {
  const formName = "user_dismissal_form"
  const model = "user_dismissal"

  const {
    refreshUserData,
    setRefreshUserData,
    objectId: editObjectId,
    setObjectId: setEditObjectId,
    selectedPerson: user,
    setShowMessage,
    handleDrawersClose,
    handleDrawersOpen,
    menuOrigin,
  } = useContext(UserContext)

  const closeUserDismissalDrawer = () => handleDrawersClose({ drawersToClose: ['userDismissal'], clearData: false })
  const openNewDrawer = () => {
    if (menuOrigin['profile']) {
      handleDrawersOpen(user, 'profile')
    }
  }

  const userName = user.presentedName

  const { trigger, handleSubmit, ...formMethods } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(userDismissalSchema),
  });

  const [submittingData, setSubmittingData] = useState(false);
  const [afterSavingButton, setAfterSavingButton] = useState(false);
  const [dismissalReason, setDismissalReason] = useState([]);
  const [editDismissalData, setEditDismissalData] = useState(null);

  const userDismissalDrawer = useRef();
  const watchingReason = formMethods.watch(`${model}.reason`)

  const dismissalAttr = ['exit_date', 'reason', 'notes_reason', 'exit_meeting_date', 'company_feedback',
                         'general_notes', 'termination_balance', 'documentation', 'invoice_total_value',
                         'installments_number']

  useEffect(() => {
    const hiringDate = moment(user.hiring_date, 'YYYY-MM-DD').toDate()
    formMethods.setValue(`${model}.hiring_date`, hiringDate)
    formMethods.setValue(`${model}.past_dismissal`, false)

    fetchDismissalReasons()
      .then((parsedResponse) => {
        setDismissalReason(parsedResponse.reason_list)
      })
      .then(() => {
        if (editObjectId) {
          fetchDismissalDetails({ id: editObjectId, user: user })
            .then((parsedResponse) => {
              const data = parsedResponse.data
              setEditDismissalData(parsedResponse.data)

              Object.keys(data).forEach(((key) => {
                if (dismissalAttr.includes(key)) {
                  formMethods.setValue(`${model}.${key}`, data[key])
                  if (key === "documentation") {
                    formMethods.setValue(`${model}.${key}_update`, data[key] ? true : false)
                  }
                }
              }));
              formMethods.setValue(`${model}.past_dismissal`, isMonthBygone(data['exit_date']))
            })
        }
      })
  }, []);

  useOnClickOutside({
    ref: userDismissalDrawer,
    handler: () => { closeUserDismissalDrawer(), openNewDrawer?.() },
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  })

  const focusFirstError = () => {
    // Find the first span with role="alert"
    const errorField = document.querySelector('span[role="alert"]');
    if (errorField) {
      // Look for the closest div that contains the errorField
      const parentDiv = errorField.closest('div');
      if (parentDiv) {
        // Find the input or select within that div
        const inputOrSelect = parentDiv.querySelector('input, select');
        if (inputOrSelect) {
          inputOrSelect.focus();
        }
      }
    }
  };

  const onSubmit = async (data) => {
    setSubmittingData(true)

    createOrUpdateDismissal({ data: { ...data }, model: 'dismissal', user: user, objectId: editObjectId })
      .then(() => {
        closeUserDismissalDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'user_dismissal_updated' : 'user_dismissal_created',
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        setRefreshUserData(!refreshUserData)
        openNewDrawer?.()
      })
      .catch(function (requestReturn) {
        setSubmittingData(false)
        console.log(requestReturn)
      })
  };

  const handleFormSubmit = async (e) => {
    setAfterSavingButton(true)
    e.preventDefault()
    const isValid = await trigger();
    if (!isValid) {
      focusFirstError()
      return;
    }
    handleSubmit(onSubmit)();
  };

  const triggerValidation = async () => { await trigger(); }

  return (
    <>
      <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen'>
        <div
          ref={userDismissalDrawer}
          className="absolute bg-white right-0 top-0 bottom-0 rounded-none w-1/2
                     z-[1500] shadow-[0px_16px_24px_rgba(0,0,0,0.14),0px_6px_30px_rgba(0,0,0,0.12),0px_8px_10px_rgba(0,0,0,0.2)]"
          id='user-dismissal-drawer'
        >
          <div id="bonus_drawer_main_body" className='block fixed right-0 left-1/2 bottom-0 top-0 '>
            <div id="initial_bonus_header_text" className="block h-fit">
              <div className={`${editObjectId ? 'bg-complementary-yellow' : 'bg-rebase-green'} z-1600 flex justify-center h-[90px]
                              md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px]
                              max-h-[15vh]`}
              >
                <img
                  className="text-center h-[95%] self-end"
                  src={PeopleImage}
                  alt={i18n.t("user_dismissal.peopleHeader")}
                />
                <button
                  id='close_user_dismissal_button'
                  type='button'
                  className='absolute top-5 right-5'
                  onClick={() => { closeUserDismissalDrawer(), openNewDrawer?.() }}
                >
                  <img
                    id='close_selection_drawer_image'
                    src={CloseButton}
                    alt={i18n.t('buttons.logout')}
                    className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                  />
                </button>
              </div>
              <div className='m-4 flex bg-white' >
                <div className="mt-0 z-[1650] bg-white" >
                  <img
                    src={DocumentImage}
                    alt={i18n.t("userDismissalDrawer.gift")}
                  />
                </div>
                <div className="mt-0 z-[1600] pl-4 bg-white w-[89%]">
                  <h5 className='font-sans not-italic font-bold text-xl/6' >
                    {i18n.t(`${model}.${editObjectId ? 'user_dismissal_update' : 'user_dismissal_create'}.title`)}
                  </h5>
                  <h6 className='font-sans not-italic text-sm/6'>
                    <p className='mt-[5px]'>{i18n.t(`${model}.${editObjectId ? 'user_dismissal_update' : 'user_dismissal_create'}.subtitle`)} <span className='font-semibold'>{userName}</span>.</p>
                  </h6>
                </div>
              </div>
            </div>
            <div
              id="user_dismissal_form_start"
              className="block max-h-[85vh] px-6 pb-2.5 pt-0 mt-0 sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0"
            >
              <FormProvider {...formMethods}>
                <form id={formName} >
                  <div
                    id="user_dismissal_form_main_body"
                    className="overflow-y-auto px-2 max-h-[70vh] absolute right-0 left-0 mx-6 px-0.5
                              sm:top-[165px] md:top-[165px] lg:top-[180px] xl:top-[191px] 2xl:top-[226px] 2k-monitor:top-[261px]
                              sm:bottom-[100px] md:bottom-[100px] lg:bottom-[105px] xl:bottom-[105px] 2xl:bottom-[100px]"
                  >
                    <div className="grid grid-cols-2 gap-y-3 gap-x-4">
                      <DateCustomInput
                        fieldId={`${model}_hiring_date`}
                        model={model}
                        name="hiring_date"
                        disabled
                        title={i18n.t(`${model}.hiring_date`)}
                        requiredField={true}
                        className={"flex items-center"}
                        divClassName={"py-2 pr-3.5 mt-2 text-md block w-full appearance-none border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11 flex items-center"}
                        titleClass={'font font-medium font-Inter leading-5 text-sm text-title-gray'}
                      />
                      <DateCustomInput
                        fieldId={`${model}_exit_date`}
                        model={model}
                        name="exit_date"
                        title={i18n.t(`${model}.exit_date`)}
                        requiredField={true}
                        disabled={editObjectId && isMonthBygone(formMethods.getValues(`${model}.exit_date`))}
                        className={"flex items-center"}
                        divClassName={"py-2 pr-3.5 mt-2 text-md block w-full appearance-none border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11 flex items-center"}
                        titleClass={'font font-medium font-Inter leading-5 text-sm text-title-gray'}
                      />

                      <HiddenInput model={model} name={`past_dismissal`} id={`${model}_past_dismissal_input`} />
                    </div>

                    <div className="grid-cols-1 space-y-2.5 mt-2.5">
                      <SelectInput
                        model={model}
                        name="reason"
                        optionsI18nPrefix={model}
                        options={dismissalReason}
                        title={i18n.t("user_dismissal.reason.title")}
                        className='w-full text-default-gray-2'
                        requiredField={true}
                      />

                      {watchingReason &&
                        <InputText
                          model={model}
                          name={'notes_reason'}
                          title={i18n.t("user_dismissal.notes_reason")}
                          className="w-full"
                          requiredField={true}
                        />
                      }

                      <DateCustomInput
                        fieldId={`${model}_exit_meeting_date`}
                        model={model}
                        name="exit_meeting_date"
                        title={i18n.t(`${model}.exit_meeting_date`)}
                        requiredField={true}
                        className={"flex items-center"}
                        divClassName={"pr-3.5 mt-2.5 text-md block w-full appearance-none border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11 flex items-center"}
                        titleClass={'font font-medium font-Inter leading-5 text-sm text-title-gray'}
                      />

                      <TextAreaInput
                        model={model}
                        name="company_feedback"
                        placeholder={i18n.t("user_dismissal.placeholder.company_feedback")}
                        title={i18n.t("user_dismissal.company_feedback")}
                        className='w-full'
                      />

                      <TextAreaInput
                        model={model}
                        name="general_notes"
                        placeholder={i18n.t("user_dismissal.placeholder.general_notes")}
                        title={i18n.t("user_dismissal.general_notes")}
                        className='w-full'
                      />

                    </div>

                    <div className="text-title-gray font-medium mt-2.5">
                      <div>
                        <p className="text-sm font-medium text-title-gray">{i18n.t(`${model}.documentation.label`)}</p>
                        <UploadFile
                          model={model}
                          id={`${model}_documentation_input`}
                          name="documentation"
                          documentImage={docIcon}
                          documentType={i18n.t(`${model}.documentation.title`)}
                          alt={i18n.t(`${model}.documentation.alt`)}
                          isBox
                          uploadTrigger={trigger}
                        />
                      </div>
                    </div>

                    <div className="mt-2.5">
                      <h5 className='font-sans not-italic font-bold text-xl/6' >
                        {i18n.t(`${model}.access_title`)}
                      </h5>
                      <p className='mt-2 font-normal not-italic text-sm/6 text-default-gray-2'>{i18n.t("user_dismissal.placeholder.access_status")} </p>
                    </div>

                    <div className="grid grid-cols-1 mt-2.5 text-sm">
                      <div className="grid grid-cols-2 gap-x-4 font-medium">
                        <p>{i18n.t(`${model}.access`)}<span className="pl-1 text-red-500">*</span></p>
                        <p>{i18n.t(`${model}.status`)}<span className="pl-1 text-red-500">*</span></p>
                      </div>

                      <UserDismissalAccess
                        formModel={model}
                        editObjectId={editObjectId}
                        editDismissalData={editDismissalData}
                      />

                      <DivisionLine customStyle='mt-[22px]' />
                    </div>

                    <div>
                      <div className="mt-[22px]">
                        <h5 className='font-sans not-italic font-bold text-xl/6' >
                          {i18n.t(`${model}.amounts_receivable`)}
                        </h5>
                        <p className='mt-2 mb-2.5 font-normal not-italic text-sm/6 text-default-gray-2'>{i18n.t(`${model}.amounts_receivable_subtitle`)} </p>
                      </div>

                      <CurrencyInput
                        model={model}
                        name={'termination_balance'}
                        title={i18n.t(`${model}.termination_balance`)}
                        placeHolder={"Ex: R$ 5.000,00"}
                        className="w-full"
                        requiredField={true}
                      />

                      <UserDismissalInstallments
                        formModel={model}
                        afterSavingButton={afterSavingButton}
                        triggerValidation={triggerValidation}
                        editObjectId={editObjectId}
                        editDismissalData={editDismissalData}
                      />

                      <DivisionLine customStyle='mt-[22px]' />
                    </div>

                    <div className="mt-[22px] mb-2.5">
                      <h5 className='font-sans not-italic font-bold text-xl/6' id='benefits-title'>
                        {i18n.t(`${model}.benefits`)}
                      </h5>
                    </div>

                    <UserDismissalSelectBenefits
                      formModel={model}
                      user={user}
                      afterSavingButton={afterSavingButton}
                      triggerValidation={triggerValidation}
                      editObjectId={editObjectId}
                      editDismissalData={editDismissalData}
                    />

                    <div id='dismissal-forms-buttons'
                      className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
                    >
                      <div className='absolute right-2.5 left-2.5 bottom-[65px]'>
                        <DivisionLine customStyle='mb-6 mt-[22px]' />

                        <CancelButton
                          buttonId={"user_dismissal_cancel_button"}
                          defaultText={i18n.t('buttons.cancel')}
                          onClick={() => { closeUserDismissalDrawer(), openNewDrawer?.() }}
                          disabled={submittingData}
                          imagePresent={false}
                          innerDivClass={'grid'}
                          type={'button'}
                          buttonClass={`absolute justify-center flex w-[70px] float-left ${editObjectId ? 'right-56' : 'right-[274px]'}`}
                        />

                        <SaveButton
                          buttonId={"user_dismissal_save_button"}
                          defaultText={i18n.t(`${model}.${editObjectId ? 'user_dismissal_update' : 'user_dismissal_create'}.submit_button_text`)}
                          submittingData={submittingData}
                          processingText={i18n.t("buttons.saving")}
                          onClick={handleFormSubmit}
                          buttonClass={`absolute right-0 flex justify-center mr-5 flex-row ${editObjectId ? 'w-[165px]' : 'w-[240px]'}`}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
