
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '#translate/i18n';

import PeopleImage from '#images/salary_readjustment_drawer/header_people.svg';
import CloseButton from '#images/add_allowances/close_icon.svg';
import CoinImage from '#images/salary_readjustment_drawer/coin.svg';

import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DateInput from '#components/formComponents/DateInput';
import InputText from '#components/formComponents/InputText';
import HiddenInput from "#components/formComponents/HiddenInput";
import DivisionLine from '#components/formComponents/DivisionLine';
import ErrorMessage from '#components/messages/ErrorMessage'
import TextAreaInput from '#components/formComponents/TextAreaInput';
import CurrencyInput from '#components/formComponents/CurrencyInput';
import salaryReadjustmentSchema from '#validations/salaryReadjustmentSchema';
import createOrUpdateSalaryReadjustment from '#requests/finances/createOrUpdateSalaryReadjustment';
import fetchSalaryReadjustment from '#requests/userProfile/editions/fetchSalaryReadjustment';

import useOnClickOutside from '#services/useOnClickOutside';
import { salaryReadjustmentData as initialValues } from '#data/salaryReadjustmentData';

export default function SalaryReadjustment({
  closeSalaryReadjustmentDrawer,
  userName,
  user,
  openNewDrawer,
  setShowMessage,
  editObjectId,
  setEditObjectId
}) {
  const formName = 'salary_update_request_form'
  const model = 'salary_update_request'

  const [serverErrors, setServerErrors] = useState()
  const [percentageValue, setPercentageValue] = useState('0%');
  const [remunerationValue, setRemunerationValue] = useState(0);
  const [submittingData, setSubmittingData] = useState(false);
  const firstReadjustment = user.first_readjustment
  const salaryAttr = ['new_value', 'valid_from', 'notes', 'current_value']

  const salaryReadjustmentDrawer = useRef();
  const scrollDivRef = useRef();
  useOnClickOutside({
    ref: salaryReadjustmentDrawer,
    handler: () => { closeSalaryReadjustmentDrawer(), openNewDrawer?.() },
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  });
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(salaryReadjustmentSchema)
  });

  useEffect(() => {
    formMethods.resetField(`${model}.current_value`, { defaultValue: user.salary })
    formMethods.resetField(`${model}.first_readjustment`, { defaultValue: firstReadjustment })
    if (editObjectId) {
      fetchSalaryReadjustment({ id: editObjectId, user: user })
        .then(response => response.json())
        .then((data) => {
          salaryAttr.forEach((attr) => {
            formMethods.setValue(`${model}.${attr}`, data[attr])
          })
          setRemunerationValue(data.new_value)
        })
    }
  }, []);

  useEffect(() => {
    const newRemunerationValue = +formMethods.getValues(`${model}.new_value`)
    const currentRemuneration = +formMethods.getValues(`${model}.current_value`)
    if (currentRemuneration && newRemunerationValue && !isNaN(newRemunerationValue)) {
      const remunerationDiff = (newRemunerationValue - currentRemuneration)
      formMethods.resetField(`${model}.readjustment`, { defaultValue: remunerationDiff })
      const percentage = (remunerationDiff / currentRemuneration) * 100
      const percentageReturn = percentage < 0 ? '0%' : (percentage.toFixed(2) + '%')
      setPercentageValue(percentageReturn)
    } else if (!newRemunerationValue) {
      setPercentageValue("0%")
      formMethods.resetField(`${model}.readjustment`, { defaultValue: 0 })
    }
  }, [remunerationValue]);

  const onSubmit = (data) => {
    setSubmittingData(true)
    createOrUpdateSalaryReadjustment({ data: data, model: model, user: user, objectId: editObjectId })
      .then(() => {
        closeSalaryReadjustmentDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'salary_readjustment_updated' : 'salary_readjustment_created'
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        openNewDrawer?.()
      })
      .catch(function (requestReturn) {
        setServerErrors(requestReturn.response.data.errors)
        setSubmittingData(false)
        scrollDivRef.current?.scrollTo({ top: 0, left: 0 })
      })
  }

  useEffect(() => {
    if(!serverErrors)  { return }

    if(serverErrors.new_value) {
      formMethods.setError(
        `${model}.new_value`, { type: "custom", message: i18n.t('salary_readjustment.forms_error.basic_server_error') }
      )
    }
    if(serverErrors.valid_from_limit) {
      formMethods.setError(
        `${model}.valid_from`, { type: "custom", message: i18n.t('salary_readjustment.forms_error.basic_server_error') }
      )
    }
  }, [serverErrors]);

  return (
    <>
      <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen'>
        <div
          ref={salaryReadjustmentDrawer}
          className='absolute bg-white right-0 top-0 bottom-0 rounded-none w-1/2
                    z-[1500] shadow-[0px_16px_24px_rgba(0,0,0,0.14),0px_6px_30px_rgba(0,0,0,0.12),0px_8px_10px_rgba(0,0,0,0.2)]'
          id='salary-readjustment-drawer'
        >
          <div className='block fixed right-0 left-1/2 bottom-0 top-0 ' >
            <div className="block h-fit">
              <div className={`${editObjectId ? 'bg-complementary-yellow' : 'bg-rebase-green'} z-1600 flex
                              justify-center h-[90px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px] max-h-[15vh]`}
              >
                <img
                  className="text-center h-[95%] self-end"
                  src={PeopleImage}
                  alt={i18n.t('salaryReadjustmentDrawer.peopleHeader')}
                />
                <button
                  id='close_salary_readjustment_drawer_button'
                  type='button'
                  className='absolute top-5 right-5'
                  onClick={() => { closeSalaryReadjustmentDrawer(), openNewDrawer?.() }}
                >
                  <img
                    id='close_selection_drawer_image'
                    src={CloseButton}
                    alt={i18n.t('buttons.logout')}
                    className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                  />
                </button>
              </div>
              <div className='m-4 flex' >
                <div className="mt-0 mr-4 z-[1650] bg-white" >
                  <img
                    src={CoinImage}
                    alt={i18n.t('salaryReadjustmentDrawer.coin')}
                  />
                </div>
                <div className="mt-0 z-[1600]" >
                  <h5 className='font-sans not-italic font-bold text-xl/6'>
                    {i18n.t(`${editObjectId ? 'salary_readjustment_update' : 'salary_readjustment_create'}.title`)}
                  </h5>
                  <h6 className='font-sans not-italic text-sm/6'>
                    <p className='mt-[5px]'>{i18n.t(`${editObjectId ? 'salary_readjustment_update' : 'salary_readjustment_create'}.subtitle`)} <span className='font-semibold'>{userName}</span>.</p>
                  </h6>
                </div>
              </div>
            </div>
            <div className="block max-h-[85vh] px-6 pb-2.5 pt-0 mt-0 sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0" >
              <FormProvider {...formMethods}>
                <form
                  id={formName}
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                  <div
                    ref={scrollDivRef}
                    className="overflow-y-auto max-h-[70vh] absolute right-0 left-0 mx-6 px-0.5
                               sm:top-[165px] md:top-[165px] lg:top-[180px] xl:top-[191px] 2xl:top-[226px] 2k-monitor:top-[261px]
                               sm:bottom-[100px] md:bottom-[100px] lg:bottom-[105px] xl:bottom-[105px] 2xl:bottom-[100px]"
                  >
                    {serverErrors && (
                      <div className='grid grid-cols-1'>
                        <ErrorMessage
                          errors={serverErrors}
                          removeErrors={setServerErrors}
                          model={'salary_readjustment'}
                          userName={userName}
                        />
                      </div>
                    )}
                    <div className='grid grid-cols-2 gap-y-3 gap-x-4 mb-[15px] mt-1'>
                      <CurrencyInput
                        model={model}
                        title={i18n.t('salary_readjustment.current_value')}
                        name='current_value'
                        disabled={true}
                        className='w-full'
                      />
                      <CurrencyInput
                        model={model}
                        name='new_value'
                        title={i18n.t('salary_readjustment.new_value')}
                        placeholder={i18n.t('salary_readjustment.new_value_placeholder')}
                        setOnChange={setRemunerationValue}
                        requiredField={true}
                        className='w-full'
                      />
                      <CurrencyInput
                        model={model}
                        name='readjustment'
                        disabled
                        title={i18n.t('salary_readjustment.readjustment')}
                        className='w-full'
                      />
                      <InputText
                        model={model}
                        name='percentage'
                        placeholder={i18n.t('salary_readjustment.percentage_placeholder')}
                        title={i18n.t('salary_readjustment.percentage')}
                        disabled
                        shouldSetValue={formMethods.getValues(`${model}.current_value`) ? true : false}
                        valuetoset={percentageValue}
                        className='w-full'
                      />
                    </div>
                    <div className='grid grid-cols-1 gap-y-3 gap-x-4 mb-5'>
                      <div className='relative mr-2.5' >
                        <DateInput
                          model={model}
                          name='valid_from'
                          title={i18n.t('salary_readjustment.valid_from')}
                          classStyle='w-full'
                          />
                        <p className='absolute top-[2px] left-[70px] text-sm font-sans not-italic font-medium text-placeholder-gra'>
                          {i18n.t('salary_readjustment.valid_from_details')}
                          <span
                            className="pl-1 text-red-500"
                          > * </span>
                        </p>
                      </div>
                      <HiddenInput model={model} name="first_readjustment" />
                      <TextAreaInput
                        model={model}
                        name='notes'
                        placeholder={i18n.t('salary_readjustment.notes_placeholder')}
                        title={i18n.t('salary_readjustment.notes')}
                        className='w-full'
                      />
                    </div>
                  </div>
                  <div id='salary_update_request_forms_buttons' className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
                  >
                    <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
                      <DivisionLine customStyle='mb-2.5' />
                      <CancelButton
                        buttonId={'salary_update_request_cancel_button'}
                        defaultText={i18n.t('buttons.cancel')}
                        onClick={() => { closeSalaryReadjustmentDrawer(), openNewDrawer?.() }}
                        disabled={submittingData}
                        imagePresent={false}
                        innerDivClass={'grid'}
                        buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-56' : 'right-72'}`}
                        type={'button'}
                      />

                      <SaveButton
                        form="salary_update_request_form"
                        defaultText={i18n.t(`${editObjectId ? 'salary_readjustment_update' : 'salary_readjustment_create'}.submit_button_text`)}
                        submittingData={submittingData}
                        processingText={i18n.t("buttons.saving")}
                        buttonId={"save_salary_update_request_button"}
                        buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-[180px]' : 'w-60'}`}
                        type={'submit'}
                      />
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
